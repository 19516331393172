.portfolio {
    display: flex;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    border-radius: var(--border-radius);
    padding: 1.875rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
    position: relative; 
    scrollbar-width: thin;
    scrollbar-color: var(--first-color) var(--container-color);
}

.timeline-line {
    position: absolute;
    top: 24px;
    left: 0;
    height: 2px;
    background-color: var(--first-color);
    z-index: 1; 
}

.timeline-point {
    width: 10px;
    height: 10px;
    background-color: var(--first-color);
    border-radius: 50%;
    position: absolute;
    top: -5px;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2; 
}

.portfolio-item {
    flex: 0 0 auto;
    width: 300px;
    padding: 1rem;
    margin-right: 1rem;
    scroll-snap-align: center;
    position: relative; 
}

.portfolio-item-content {
    border-left: 2px solid var(--first-color);
    padding-left: 1rem;
}

.portfolio-item:last-child {
    margin-right: 0;
}

.portfolio-item-box { 
    background-color: var(--container-color);
    border: 2px solid var(--first-color);
    border-radius: var(--border-radius);
    padding: 0.8rem;
}

.portfolio-item-title {
    font-size: 1.275rem;
    font-weight: bold;
    color: var(--title-color);
}

.portfolio-item-date {
    font-size: 0.875rem;
    color: var(--text-color);
    margin-bottom: 0.5rem;
    text-align: center;
}

.portfolio-item-description {
    font-size: 0.975rem;
    color: var(--text-color);
    line-height: 1.6;
}

.portfolio-item-description a {
    color: var(--title-color); 
    text-decoration: none;
    margin-left: 5px;
}

.portfolio-item-description a:hover {
    color: hsl(43, 100%, 68%);
}


.portfolio::-webkit-scrollbar {
    width: 10px;
}

.portfolio::-webkit-scrollbar-track {
    background-color: var(--container-color);
}

.portfolio::-webkit-scrollbar-thumb {
    background-color: var(--first-color);
    border-radius: 5px;
}
