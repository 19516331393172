.main {
    margin-left: 110px;
}

@media screen and (max-width: 1024px) {
    .main {
        margin-left: 0;
    }
}

.switch {
    position: fixed;
    top: 1.25rem;
    right: 100px;
    z-index: 1;
}

@media screen and (max-width: 1024px) {
    .switch {
        right: 120px;

    }
}