.about__container {
    grid-template-columns: 3fr 9fr;
    column-gap: 1.875rem;
}

.about__img {
    border-radius: 80%;
    width: 300px;
}

.about__data {
    padding: 1.875rem;
    background-color: var(--container-color);
    box-shadow: var(--shadow);
    border-radius: var(--border-radius);
    grid-template-columns: repeat(2, 1fr);
    column-gap: 1.875rem;
    align-items: flex-start;
    position: relative;
    text-align: center;
}

.about__data::before {
    content: '';
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-right: 15px solid var(--container-color);
    position: absolute;
    left: -0.93rem;
    top: 20%;
}

.about__description {
    margin-bottom: 1rem;
    text-align: justify;
}

.about__skills.grid {
    align-items: center;
    padding-left: 40px;
    padding-top: 10px;
    padding-right: 10px;
    margin-right: 0;
    height: 380px;
    position: relative;
}
  
.legend-badge {
    display: inline-flex;
    align-items: center;
    padding: 0.2rem 0.5rem;
    border-radius: 1.875rem;
    box-shadow: 0 0 1px rgba(0, 0, 0, 0%);
    border: 1px solid transparent;
    color: #fff;
    background-color: #1A1A1A;
    font-weight: var(--font-bold);
    margin-right: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
}
  
.legend-color {
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin-right: 5px;
}
  
.legend-text {
    font-size: 10px;
}  

.about__boxes {
    grid-template-columns: repeat(4, 1fr);
    column-gap: 1.875rem;
    margin-top: 4.25rem;
}

.about__box {
    display: flex;
    column-gap: 1.5rem;
}

.about__icon {
    font-size: var(--h1-font-size);
    color: #dedeea;
}

.about__title {
    font-size: 1.875rem;
}

@media screen and (max-width: 1024px) {
    .about__container {
        grid-template-columns: 720px;
        justify-content: center;
        row-gap: 1.875rem;
    }

    .about__data::before {
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 10px solid #fff;
        left: 49%;
        top: -20px;
    }

    .about__img {
        justify-self: center;
        border-radius: 80%;
        width: 200px;
    }

    .about__box {
        flex-direction: column;
        row-gap: .5rem;
        text-align: center;
    }
}

@media screen and (max-width: 768px) {
    .about__container {
        grid-template-columns: 350px;
    }

    .about__data::before {
        left: 47%;
    }

    .about__data {
        grid-template-columns: 1fr;
        row-gap: 1.875rem;
        justify-content: center;
        display: block;
    }

    .about__boxes {
        grid-template-columns: repeat(2, 150px);
        row-gap: 1.5rem;
        justify-content: center;
    }

}


@media screen and (max-width: 576px) {
    .about__container {
        grid-template-columns: 320px;
    }
}

@media screen and (max-width: 350px) {
    .about__container {
        grid-template-columns: 1fr;
    }

    .about__boxes {
        grid-template-columns: 1fr;
    }
}